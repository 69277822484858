// import ImageUrlBuilder from "@sanity/image-url";
// import React from "react";
import { useParams } from "react-router-dom";

const PaymentForwarder = ({
    props

}) => {
    const { id } = useParams();
    // console.log('id: ', id);

    window.location.href = `https://app.factuurtje.online/invoice/payment/${id}`
    return null
};

export default PaymentForwarder;