import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import client from '../client';
import serializers from './../components/serializers';

const { projectId, dataset } = client.config()

function SimpleBlockContent(props) {
    const { blocks } = props

    if (!blocks) {
        console.error('Missing blocks')
        return null
    }

    return <BlockContent blocks={blocks} serializers={serializers} projectId={projectId} dataset={dataset} />
}

export default SimpleBlockContent
