import React from 'react';
// import { Link } from "react-router-dom";
// import styles from './Cta.module.css';

function cta(props) {
    const { title, route, link } = props;

    // External links
    if (route && route.slug && route.slug.current) {
        return (
            <div className="inline-flex rounded-md shadow">
                <a
                    href={{
                        pathname: '/LandingPage',
                        query: { slug: route.slug.current }
                    }}
                    // href="https://app.factuurtje.online/register"
                    // className="sm:ml-3 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-700 bg-gray-100 hover:text-gray-600 hover:bg-gray-50 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"

                >
                    {title}
                </a>
            </div>

            // <Link
            //     href={{
            //         pathname: '/LandingPage',
            //         query: { slug: route.slug.current }
            //     }}
            //     as={`/${route.slug.current}`}
            // >
            //     <a className="btn">{title}</a>
            // </Link>
        )
    }

    // internal link
    if (link) {
        return (
            <div className="sm:mt-0 sm:ml-3 inline-flex rounded-md shadow">
                <a href={link}
                    // className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-700 bg-gray-100 hover:text-gray-600 hover:bg-gray-50 focus:outline-none focus:shadow-outline-gray focus:border-gray-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                    {title}
                </a>
            </div>

            // <a className="btn" href={link}>
            //     {title}
            // </a>
        )
    }

    // return <a className="btn">{title}</a>
}


export default cta;
