import React from 'react';
// import imageUrlBuilder from '@sanity/image-url';
// import styles from './Hero.module.css'
// import client from '../client';
import SimpleBlockContent from './SimpleBlockContent';
import Cta from './Cta';
import { urlFor } from '../helper';

// function urlFor(source) {
//     return imageUrlBuilder(client).image(source)
// }

function Hero(props) {
    const { heading, backgroundImage, tagline, ctas } = props

    // const style = backgroundImage
    //     ? {
    //         backgroundImage: `url("${urlFor(backgroundImage)
    //             .width(2000)
    //             .auto('format')
    //             .url()}")`
    //     }
    //     : {}

    return (
        // <div className="" style={style}>===HERO===
        //     <div className="">
        //         <h1 className="">{heading}</h1>
        //         <div className="">{tagline && <SimpleBlockContent blocks={tagline} />}</div>
        //         {ctas && (
        //             <div className="">
        //                 {ctas.map(cta => (
        //                     <Cta {...cta} key={cta._key} />
        //                 ))}
        //             </div>
        //         )}
        //     </div>
        // </div>
        <div className="relative bg-white overflow-hidden">
            <div className="max-w-screen-xl mx-auto">
                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

                    <div className="relative pt-6 px-4 sm:px-6 lg:px-8">

                    </div>

                    <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">
                            <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                {heading}
                            </h2>
                            <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                {tagline && <SimpleBlockContent blocks={tagline} />}
                                {/* <BlockContent
                                    blocks={data.text}
                                    projectId={sanityClient.clientConfig.projectId}
                                    dataset={sanityClient.clientConfig.dataset}
                                /> */}
                            </div>
                            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                {ctas && (
                                    <div className="">
                                        {ctas.map(cta => (
                                            <Cta {...cta} key={cta._key} />
                                        ))}
                                    </div>

                                )}
                                {/* <div className="inline-flex rounded-md shadow">
                                    <a href="https://app.factuurtje.online/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                                        Gratis beginnen
                                </a>
                                </div>
                                <div className="sm:mt-0 sm:ml-3 inline-flex rounded-md shadow">
                                    <a href="https://app.factuurtje.online/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                                        Inloggen
                                </a>
                                </div> */}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img alt="factuurtje online" className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={urlFor(backgroundImage).width(800).url()} />
                {/* <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="" /> */}
            </div>
        </div>

    )
}

export default Hero;
