import React from 'react';
import SimpleBlockContent from './SimpleBlockContent';
// import styles from './TextSection.module.css';

function TextSection(props) {
    const { heading, label, text } = props

    return (
        // <div className="flex">===TEXTSECTION===
        //     <section className="pb-16 w-full pt-24 lg:pt-28">

        //         <div className="markdown mx-auto xl:px-12">
        //             <div className="flex items-center markdown">
        //                 <h1>Responsive Design</h1>
        //             </div>
        //             <p className="mt-0 mb-4 text-gray-600">Using responsive utility variants to build adaptive user interfaces.</p>
        //             <hr className="my-8 border-b-2 border-gray-200" />
        //         </div>

        //         <div className="markdown mb-6 px-6 max-w-3xl mx-auto lg:ml-0 lg:mr-auto xl:mx-0 xl:px-12 xl:w-3/4">
        //             <div className="">{label}</div>
        //             <h2 className="">{heading}</h2>
        //             {text && <SimpleBlockContent blocks={text} />}
        //         </div>
        //     </section>
        // </div>


        <div className="container pt-24">===TEXTSECTION===

            <div className="row">
                <div className="col-12 align-self-center">
                    <div className="flex items-center markdown">
                        <h1>{heading}</h1>
                    </div>
                    <p className="mt-0 mb-4 text-gray-600">{label}</p>
                    <hr className="my-8 border-b-2 border-gray-400" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 align-self-center">
                    {text && <SimpleBlockContent blocks={text} />}
                </div>
            </div>

        </div>

    )
}


export default TextSection
