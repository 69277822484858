import React, { useState } from "react";
import {
    faColumns,
    faCoins,
    faSignInAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { NavLink } from 'react-router-dom';
import { urlFor } from '../helper';

const HeaderBar = ({
    logodata,
    ...props
}) => {

    const [collapseOpen, setCollapseOpen] = useState(false);

    const toggleNavbar = () => {
        setCollapseOpen(!collapseOpen);
    }

    return (
        <React.Fragment>

            <nav className="headerbar-background navbar navbar-expand-lg navbar-dark">
                <a href="/" className="navbar-brand">
                    <img alt="factuurtje online logo" className="header-logo" src={urlFor(logodata).width(100).url()} />
                </a>

                <button data-testid="toggleNavbar"
                    type="button"
                    className="navbar-toggler"
                    onClick={() => toggleNavbar()}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>



                <div className={`collapse navbar-collapse ${collapseOpen && ('show')}`}>

                    <ul className="ml-auto navbar-nav">
                        <li className="nav-item">
                            <a href="#why" className="nav-link"><FontAwesomeIcon icon={faColumns}></FontAwesomeIcon> Waarom factuurtje online</a><br />
                        </li>

                        <li className="nav-item">
                            <a href="#pricing" className="nav-link"><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon> Prijzen</a><br />
                        </li>

                        <li className="nav-item">
                            <a href="https://app.factuurtje.online/login" className="nav-link"><FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon> Inloggen</a><br />
                        </li>

                    </ul>
                </div>
            </nav>
        </React.Fragment>
    );
};

export default HeaderBar;