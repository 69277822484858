import React from "react";

const FeatureSection = () => {

    return (
        <div className="py-12 bg-white">
            <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <p className="text-base leading-6 text-gray-600 font-semibold tracking-wide uppercase">Free-Tier</p>
                    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        Ons prijsmodel
                    </h3>
                    <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                        Wij werken niet met abonnementen of vaste kosten. Alle opties en mogelijkheden zijn altijd gratis binnen een bepaald niveau (free-tier).
                        Bij ons betaal je per gebruik. Door een bedrag in de account te storten kan je betalen voor de kosten als je over het gratis niveau heen gaat.
                    </p>
                </div>

                <div className="mt-10">
                    <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        <li>
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">5 facturen per maand altijd gratis.</h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        De eerste vijf facturen per maand verstuur je gratis. De volgende 50 facturen kosten 12ct per factuur. Wanneer je meer dan 50 facturen verstuurd zijn de kosten nog maar 5ct per factuur.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Tot 100mb factuur opslag altijd gratis.</h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">Wij bewaren alle factuur in de cloud voor je. Zo raak je de verzonden facturen nooit meer kwijt. De eerste 100mb opslag is gratis. Dit zijn ongeveer 500 facturen.
                                    Ga je over de 100mb heen dan reken we per 1gb opslag 50ct per maand.
                                        </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">100 klanten per maand altijd gratis.</h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        Maak zo veel klanten aan als je wil. Het beheer over de eerste 100 klanten is gratis. Wanneer je meer dan 100 klanten gaat beheren betaal je 50ct voor elke extra 1000 klanten per maand.
                                        </p>
                                </div>
                            </div>
                        </li>
                        <li className="mt-10 md:mt-0">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="text-lg leading-6 font-medium text-gray-900">Geen verborgen kosten.</h4>
                                    <p className="mt-2 text-base leading-6 text-gray-500">
                                        Je kan alle functies van factuurtje online gebruiken. Of je nu 1 of 100 facturen per maand verzend.
                                        Wil je een koppeling maken met iDeal dan reken we 1 euro per maand voor het gebruuk van de koppeling met iDeal.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default FeatureSection;
