// import EmbedHTML from './EmbedHTML';
// import Figure from './Figure';
import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
// import styles from './Figure.module.css'
import client from '../client';


const builder = imageUrlBuilder(client);

const EmbedHTML = ({ node }) => {
    const { html } = node;
    if (!html) {
        return undefined
    }
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} />
    );
};

const Figure = ({ node }) => {
    const { alt, caption, asset } = node;
    if (!asset) {
        return undefined;
    }
    return (
        <figure className="">
            <img
                src={builder
                    .image(asset)
                    .auto('format')
                    .width(2000)
                    .url()}
                className=""
                alt={alt}
            />
            {caption && (
                <figcaption>
                    <div className="">
                        <div className="">
                            <p>{caption}</p>
                        </div>
                    </div>
                </figcaption>
            )}
        </figure>
    );
};

const serializers = {
    // use the list and listItem outside the types
    //list: props => <div className="pb-3 pt-3">{props.children}</div>,
    list: props => <ul className="list-disc list-inside">{props.children}</ul>,

    // listItem: props => <strong>{props.children}</strong>,

    types: {
        embedHTML: EmbedHTML,
        figure: Figure
    }
};

export default serializers;