import React from 'react';
import SimpleBlockContent from './SimpleBlockContent';
import { urlFor } from './../helper';

function ImageSection(props) {
    const { heading, label, text, image, } = props;
    // console.log('cta: ', cta);

    if (!image) {
        return null
    }

    return (
        // <div className="">=== IMAGESECTION===
        //     <figure className="">
        //         <img
        //             src={builder
        //                 .image(image)
        //                 .auto('format')
        //                 .width(2000)
        //                 .url()}
        //             className=""
        //             alt={heading}
        //         />
        //         <figcaption>
        //             <div className="">
        //                 <div className="">
        //                     <div className="">{label}</div>
        //                     <h2 className="">{heading}</h2>
        //                     {text && <SimpleBlockContent blocks={text} />}
        //                     {cta && cta.route && <Cta {...cta} />}
        //                 </div>
        //             </div>
        //         </figcaption>
        //     </figure>
        // </div>


        <div className="container pt-24">
            <div id="why"></div>
            <div className="row">
                <div className="col-12 align-self-center">
                    <div className="flex items-center markdown">
                        <h1>{heading}</h1>
                    </div>
                    <p className="mt-0 mb-4 text-gray-600">{label}</p>
                    <hr className="my-8 border-b-2 border-gray-400" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-8  align-self-center">
                    {text && <SimpleBlockContent blocks={text} />}
                </div>
                <div className="col-12 col-lg-4 align-self-center text-center">
                    <img alt="factuurtje online" className="w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={urlFor(image).width(300).url()} />
                    <small className="mt-0 mb-4 text-gray-600">{image.caption}</small>
                </div>
            </div>

        </div>

    )
}


export default ImageSection;
