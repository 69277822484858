import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import HomePage from './components/homepage';
import ContentPage from './components/contentpage';
import HeaderBar from './components/header';
import PaymentForwarder from './components/forwarder';

import sanityClient from './client';
// import groq from 'groq';

const siteConfigQuery = `
  *[_id == "global-config"] {
    ...,
    logo {asset->{extension, url}},
    mainNavigation[] -> {
      ...,
      "title": page->title
    },
    footerNavigation[] -> {
      ...,
      "title": page->title
    }
  }[0]
  `
function App() {
  const [siteData, setSiteData] = useState(null);

  useEffect(() => {
    sanityClient.fetch(siteConfigQuery)
      .then(response => {
        setSiteData(response);

        // document.title = siteData.title || 'Default title';
        // document.querySelector('meta[name="description"]').setAttribute('content', siteData.metaDescription || 'Default description');
        // document.url = siteData.url || "https://factuurtje.online";
        // document.language = siteData.lang || "nl-NL";

        // one day using react helmet for this
        // https://github.com/nfl/react-helmet

      })
      .catch(console.error);

  }, [])

  if (!siteData) return <div>Loading...</div>

  return (
    <BrowserRouter>
      <HeaderBar logodata={siteData.logo} />
      <div className="main-container">
        <Route path="/" component={HomePage} exact />
        <Route path="/:slug" component={ContentPage} exact />
        <Route path="/invoice/payment/:id" component={PaymentForwarder} exact />

        {/* https://factuurtje.online/invoice/payment/ffb58b7a-0ef9-4834-8f41-a2190fa640e2 */}

        <Route component={HomePage} />

      </div>
    </BrowserRouter>
  );
}

export default App;
