// import ImageUrlBuilder from "@sanity/image-url";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from '../client';
// import BlockContent from '@sanity/block-content-to-react';
import RenderSections from '../sections/rendersections';
import groq from 'groq';

const pageQuery = groq`
*[_type == "route" && slug.current == $slug][0]{
  page-> {
    ...,
    content[] {
      ...,
      cta {
        ...,
        route->
      },
      ctas[] {
        ...,
        route->
      }
    }
  }
}
`

const ContentPage = () => {
  const [postData, setpostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        //     `*[slug.current == $slug]{
        //     title,
        //     slug,
        //     mainImage{
        //         asset->{
        //             _id,
        //             url
        //         }
        //     },
        //     body,
        //     "name": author->name,
        //     "authorImage": author->image
        // }`
        pageQuery,
        { slug }
      )
      .then(response => {
        // console.log('response: ', response);
        setpostData(response.page?.content);
      }
      )
      .catch(console.error);

  }, [slug]);

  // if (!postData) return <div>Loading...</div>

  return (
    <div>

      {postData && <RenderSections sections={postData} />}

      {/* <div>
                <h2>{postData.title}</h2>
                <div>
                    <img alt="factuurtje online" src={urlFor(postData.authorImage).width(100).url()} />
                    <h4>{postData.name}</h4>
                </div>
                <img alt="factuurtje online" src={urlFor(postData.mainImage).width(200).url()} />
                <div>
                    <BlockContent
                        blocks={postData.body}
                        projectId={sanityClient.clientConfig.projectId}
                        dataset={sanityClient.clientConfig.dataset}
                    />
                </div>
            </div> */}
    </div>
  )
};

export default ContentPage;