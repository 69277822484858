import React, { useEffect, useState } from "react";
import sanityClient from '../client';
import FeatureSection from '../sections/features.homepage';
import groq from 'groq';
import RenderSections from '../sections/rendersections';

// const pageQuery = groq`
// *[_type == "route" && slug.current == $slug][0]{
//   page-> {
//     ...,
//     content[] {
//       ...,
//       cta {
//         ...,
//         route->
//       },
//       ctas[] {
//         ...,
//         route->
//       }
//     }
//   }
// }
// `

const HomePage = () => {
  // const [homePageData, setHomePageData] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    // getting the home page deetails
    sanityClient.fetch(
      groq`
            *[_id == "global-config"][0]{
              frontpage -> {
                ...,
                content[] {
                  ...,
                  cta {
                    ...,
                    route->
                  },
                  ctas[] {
                    ...,
                    route->
                  }
                }
              }
            }
          `
    )
      .then(response => {
        setPageContent(response.frontpage.content);


        var homepage = {}
        homepage.title = response.frontpage.title;

        // setup the correct data items
        response.frontpage.content.map(content => {
          if (content._type === 'hero') {
            homepage.backgroundImage = content.backgroundImage;
            homepage.heading = content.heading
          }

          if (content._type === 'textSection') {
            homepage.label = content.label;
            homepage.text = content.text
          }
          return homepage;
        });
        // setHomePageData(homepage);

      })
      .catch(console.error);
  }, []);

  // if (!homePageData) return <div>Loading..</div>

  return (
    <div>

      {pageContent && <RenderSections sections={pageContent} />}

      {/* <div>
                {homePageData &&
                    homePageData.frontpage.content.map((post, index) => (
                        <Link to={'/' + post.slug.current} key={post.slug.current}>
                            <span key={index}>
                                <img src={post.mainImage.asset.url} alt="image" />
                                <span>
                                    <h2>{post.title}</h2>
                                </span>
                            </span>
                        </Link>
                    ))
                }
            </div> */}

      {/* <HeroSection data={homePageData} /> */}

      {/* <div className="bg-gray-50">
                <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                    iets hiero.
                </div>
            </div>*/}

      <div className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Klaar voor de start?
            <br />
            <span className="text-gray-600">GO!</span>
          </h2>
          <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
            <div className="inline-flex rounded-md shadow">
              <a href="https://app.factuurtje.online/register" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Gratis account
              </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a href="https://app.factuurtje.online/login" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-600 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Nu inloggen
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="pricing"></div>
      <FeatureSection />





    </div>
  )
};

export default HomePage;